import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom"
import Landing from "../Pages/Landing"
import Inquire from "../Pages/Inquire"
import Packages from "../Pages/Packages"

const Router = () => {



    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route path="/" index element={<Landing />} />
                    <Route path="/packages" element={<Packages />} />
                    <Route path="/inquire" element={<Inquire />} />
                </Routes>
            </BrowserRouter>
        </div>
    )
}
export default Router