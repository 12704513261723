import Header from "../Components/Header/Header"
import Body from "../Components/Body/Main/Body"
import Footer from "../Components/Footer/Footer"

const Landing = () => {
    return (
        <div className="bg-neutral-900">
            <Header />
            <Body />
            <Footer />
        </div>
    )
}

export default Landing