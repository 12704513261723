import { FaFacebookSquare, FaInstagramSquare } from "react-icons/fa"

const Footer = () => {

    return (
        <div className="min-h-40 bg-orange-800 text-gray-200  max-w-screen">
            <div className="md:ml-10 pt-3 text-center md:text-left">
                <p className="text-2xl ">
                    Contact us
                </p>
            </div>
            <div className="grid grid-cols-12 pb-10">
                <div className="sm:col-span-10 col-span-12 flex flex-col px-10 pt-5">

                    <div className="text-center md:text-left w-1/2 mx-auto md:m-0">
                        <p className="font-bold">Email</p>
                        <div>
                            <a href="mailto:Maddoggdetailing@outlook.com" className="hover:text-neutral-300 duration-200 ease-in-out">Maddoggdetailing@outlook.com</a>
                        </div>
                    </div>

                    <div className="text-center md:text-left w-1/2 mx-auto md:m-0">
                        <p className="font-bold">Mobile: </p>
                        <div>
                            <p>(+61) 0423 145 054</p>
                        </div>
                    </div>
                </div>
                <div className="col-span-12 sm:col-span-1 flex sm:flex-col flex-row py-5 justify-center">
                    <div className="text-4xl">
                        <a href="https://www.facebook.com/mad.dogg.detailing" target="new"><FaFacebookSquare className="hover:text-neutral-400 duration-200 ease-in-out" /></a>
                    </div>
                    <div className="text-4xl">
                        <a href="https://www.instagram.com/mad_dogg_detailing/?igshid=YTQwZjQ0NmI0OA%3D%3D&utm_source=qr" target="new"><FaInstagramSquare className="hover:text-neutral-400 duration-200 ease-in-out" /></a>
                    </div>
                </div>
            </div>

        </div>
    )

}

export default Footer