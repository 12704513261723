import front from "../../static/img/Subaru/front.jpg"
import rear from "../../static/img/Subaru/rear.jpg"
import whole from "../../static/img/Subaru/whole.jpg"

import { FaChevronRight } from "react-icons/fa"

const ShowcaseLower = () => {

    return (
        <div className="w-full md:mt-20">
            <div className=" mb-10 text-center">
                <p className="text-gray-200 xl:text-6xl text-3xl font-light">
                    Not just your average detailer
                </p>
            </div>
            <div className="">
                <div className="col-span-6 overflow-hidden shadow-3 ">
                    <div className="w-[90%] mx-auto flex xl:flex-row flex-col xl:gap-20 gap-10 my-6">
                        <div>
                            <img src={front} className="" alt="Subaru front" />
                        </div>
                        <div>
                            <img src={rear} className="" alt="Subaru rear" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-[90%] mx-auto flex xl:flex-row flex-col xl:gap-20 gap-10 my-6">
                <div className="xl:w-1/2">
                    <img src={whole} className="" alt="Subaru whole" />
                </div>
                <div className=" xl:w-1/2">

                    <p className="xl:text-3xl text-xl text-gray-200">
                        Being a car enthusiast means we have an obsessive eye for detail. Meaning your pride and joy becomes ours whilst in our care.
                        <br /><br />
                        With Years of experience from exotics to classic muscle and everything between, we have you covered from a standard clean to paint correction.
                    </p>
                </div>
            </div>
            <div className="h-full flex flex-col justify-center md:w-[70%] mx-auto">
                <div className="mt-20 mb-3 text-center">
                    <p className="text-gray-200 xl:text-6xl text-3xl font-light">
                        Why Mad Dogg Detailing?
                    </p>
                </div>

                <div className=" md:w-[70%] mx-auto  p-5 mb-5">
                    <ul className="text-gray-200 xl:text-2xl text-xl md:text-lg font-light ">
                        <li className="mt-10 flex">
                            <FaChevronRight className="inline mt-2 mr-4" /><div className="inline"><p>100% mobile, we come to you wherever you are</p></div>
                        </li>
                        <li className="mt-10 flex">
                            <FaChevronRight className="inline mt-2 mr-4" /><div className="inline"><p>Cars are our passion! Your Pride and joy becomes ours</p></div>
                        </li>
                        <li className="mt-10 flex">
                            <FaChevronRight className="inline mt-2 mr-4" /><div className="inline"><p>Using only the best products and materials available to ensure the job is done to 100%</p></div>
                        </li>
                        <li className="mt-10 flex">
                            <FaChevronRight className="inline mt-2 mr-4" /><div className="inline"><p>Professional timely service every time</p></div>
                        </li>
                        <li className="mt-10 flex">
                            <FaChevronRight className="inline mt-2 mr-4" /><div className="inline"><p>Specialising in modified cars, Custom Motorbikes and more</p></div>
                        </li>
                        <li className="mt-10 flex">
                            <FaChevronRight className="inline mt-2 mr-4" /><div className="inline"><p>Trade and Fleet discounts</p></div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )

}

export default ShowcaseLower