import TopBarInquire from "../Components/Header/TopBarInquire"
import Footer from "../Components/Footer/Footer"
import { Field, Form, Formik } from "formik"
import { useEffect, useState } from "react"
import { init, send } from "@emailjs/browser"
import * as Yup from 'yup'
import { ScrollRestoration, useLocation } from "react-router-dom";


const Inquire = () => {

    const location = useLocation();

    const [loading, setLoading] = useState(false)
    const [emailError, setEmailError] = useState("")
    const [emailSuccessMessage, setEmailSuccessMessage] = useState("")

    useEffect(() => {
        init('VdUaa-KVPmYLAzt3_')
        window.scrollTo(0, 0);
    }, [])


    return (
        <div className="min-h-[100vh] flex flex-col justify-between text-gray-200" id="inquire">
            <TopBarInquire />
            <div className={" z-0 ease-in-out duration-300 rounded-xl flex justify-center transition-all relative "}>
                <div className={"lg:w-[60%] w-full h-full bg-neutral-800 shadow-lg rounded-xl md:my-20 my-10 "}>
                    <div className="md:text-4xl text-3xl w-[90%] mx-auto border-b border-orange-500 py-2 mt-5 mb-10">
                        Contact Us
                    </div>
                    <Formik
                        validationSchema={
                            Yup.object().shape({
                                firstName: Yup.string().required('Required field'),
                                lastName: Yup.string().required('Required field'),
                                email: Yup.string().email('Invalid email').required('Required field'),
                                phone: Yup.string().min(8).required('Required field'),
                                postcode: Yup.string().required('Required field'),
                                contactMethod: Yup.string().required('Required field'),
                                vehicleMakeAndModel: Yup.string().required('Required field'),
                                message: Yup.string().required('Required field'),
                            })
                        }
                        initialValues={{
                            firstName: "",
                            lastName: "",
                            email: "",
                            phone: "",
                            postcode: "",
                            contactMethod: "email",
                            vehicleMakeAndModel: "",
                            message: location.state ? ("" + location.state.name + " - $" + location.state.price + "\n") : ""
                        }}
                        onSubmit={(values, { resetForm }) => {
                            send('service_q8j38cl', 'template_wlqdzn9', values)
                                .then((result) => {
                                    setLoading(() => false)
                                    setEmailSuccessMessage(() => "Thank you for your email " + values.firstName + ", we'll be in touch shortly.")
                                    resetForm();
                                    setTimeout(() => {
                                        setEmailSuccessMessage(() => "")
                                    }, 4000)
                                }, (error) => {
                                    console.log(error.text);
                                    setEmailError(() => "There was an error sending the email: " + error.text)
                                });
                        }}
                    >
                        {(formik) => (
                            <Form className="w-[90%] mx-auto my-5">
                                {
                                    emailError != "" ?
                                        <div class={"md:flex md:items-center mb-6 mt-6 "}>
                                            <div className="m-4 p-2 rounded-2xl w-full bg-red-500">
                                                <div className="w-full text-gray-200 text-center font-bold">
                                                    {emailError}
                                                </div>
                                                <div className="w-full text-gray-200 text-center">
                                                    If this persists, please use our contact details at the bottom of the page.
                                                </div>
                                            </div>

                                        </div>
                                        :
                                        <></>
                                }
                                {
                                    emailSuccessMessage != "" ?
                                        <div class={"md:flex md:items-center mb-6 mt-6 "}>
                                            <div className="m-4 p-2 rounded-2xl w-full bg-green-700">
                                                <div className="w-full text-gray-200 text-center">
                                                    {emailSuccessMessage}
                                                </div>
                                            </div>

                                        </div>
                                        :
                                        <></>
                                }
                                <div className="flex flex-col md:flex-row w-full md:gap-10 gap-2">
                                    <div className="w-full">
                                        <div className="">
                                            <p className="text-2xl font-thin">
                                                First Name
                                            </p>
                                        </div>
                                        <Field
                                            placeholder={""}
                                            className="rounded-sm border bg-gray-100 border-gray-300 my-2 text-lg px-1 py-2 w-full text-neutral-700"
                                            name="firstName" />
                                        {formik.errors.firstName && formik.touched.firstName ? (
                                            <div className="text-gray-200 bg-red-500 w-full rounded-lg p-2 text-center">{formik.errors.firstName}</div>
                                        ) : null}
                                    </div>
                                    <div className="w-full">
                                        <div className="">
                                            <p className="text-2xl font-thin">

                                                Last Name
                                            </p>
                                        </div>
                                        <Field
                                            placeholder={""}
                                            className="rounded-sm border bg-gray-100 border-gray-300 my-2 text-lg px-1 py-2 w-full text-neutral-700"
                                            name="lastName" />
                                        {formik.errors.lastName && formik.touched.lastName ? (
                                            <div className="text-gray-200 bg-red-500 w-full rounded-lg p-2 text-center">{formik.errors.lastName}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="my-4 w-full">
                                    <div className="">
                                        <p className="text-2xl font-thin">

                                            Email
                                        </p>
                                    </div>
                                    <Field
                                        placeholder={"Email Address"}
                                        className="rounded-sm border bg-gray-100 border-gray-300 my-2 text-lg px-1 py-2 w-full text-neutral-700"
                                        name="email" />
                                    {formik.errors.email && formik.touched.email ? (
                                        <div className="text-gray-200 bg-red-500 w-full rounded-lg p-2 text-center">{formik.errors.email}</div>
                                    ) : null}
                                </div>
                                <div className="my-4 w-full">
                                    <div className="">
                                        <p className="text-2xl font-thin">

                                            Phone Number
                                        </p>
                                    </div>
                                    <Field
                                        placeholder={"Phone Number"}
                                        className="rounded-sm border bg-gray-100 border-gray-300 my-2 text-lg px-1 py-2 w-full text-neutral-700"
                                        name="phone" />
                                    {formik.errors.phone && formik.touched.phone ? (
                                        <div className="text-gray-200 bg-red-500 w-full rounded-lg p-2 text-center">{formik.errors.phone}</div>
                                    ) : null}
                                </div>
                                <div className="my-4 w-full">
                                    <div className="">
                                        <p className="text-2xl font-thin">

                                            Postcode
                                        </p>
                                    </div>
                                    <Field
                                        placeholder={"Postcode"}
                                        className="rounded-sm border bg-gray-100 border-gray-300 my-2 text-lg px-1 py-2 w-full text-neutral-700"
                                        name="postcode" />
                                    {formik.errors.postcode && formik.touched.postcode ? (
                                        <div className="text-gray-200 bg-red-500 w-full rounded-lg p-2 text-center">{formik.errors.postcode}</div>
                                    ) : null}
                                </div>
                                <div className="my-4 w-full">
                                    <div className="">
                                        <p className="text-2xl font-thin">

                                            Favoured Method of Contact
                                        </p>
                                    </div>
                                    <Field
                                        as={"select"}
                                        placeholder={""}
                                        className="rounded-sm border bg-gray-100 border-gray-300 my-2 text-lg px-1 py-2 w-full text-neutral-700"
                                        name="contactMethod" >
                                        <option value="email">Email</option>
                                        <option value="phone">Phone</option>
                                    </Field>
                                    {formik.errors.contactMethod && formik.touched.contactMethod ? (
                                        <div className="text-gray-200 bg-red-500 w-full rounded-lg p-2 text-center">{formik.errors.contactMethod}</div>
                                    ) : null}
                                </div>
                                <div className="my-4 w-full">
                                    <div className="">
                                        <p className="text-2xl font-thin">

                                            Vehicle Make and Model
                                        </p>
                                    </div>
                                    <Field

                                        placeholder={"eg. Subaru WRX, Nissan Skyline"}
                                        className="rounded-sm border bg-gray-100 border-gray-300 my-2 text-lg px-1 py-2 w-full text-neutral-700"
                                        name="vehicleMakeAndModel" />
                                    {formik.errors.vehicleMakeAndModel && formik.touched.vehicleMakeAndModel ? (
                                        <div className="text-gray-200 bg-red-500 w-full rounded-lg p-2 text-center">{formik.errors.vehicleMakeAndModel}</div>
                                    ) : null}
                                </div>
                                <div className="my-4 w-full">
                                    <div className="">
                                        <p className="text-2xl font-thin">

                                            Message
                                        </p>
                                    </div>
                                    <div>
                                        <Field
                                            component="textarea" rows="7"
                                            placeholder={"Inquiry about your vehicle, the services you'd like, any notes about the vehicle, location and times that work best for you."}
                                            className="rounded-sm border bg-gray-100 border-gray-300 my-2 text-lg px-1 py-2 w-full text-neutral-700"
                                            name="message" />
                                        {formik.errors.message && formik.touched.message ? (
                                            <div className="text-gray-200 bg-red-500 w-full rounded-lg p-2 text-center">{formik.errors.message}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="flex pb-3">
                                    <button type="submit" className="bg-orange-500 w-full md:w-3/4 mx-auto py-4 text-2xl rounded-xl hover:bg-orange-400 duration-200 ease-in-out hover:text-white font-semibold">
                                        Submit
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                    <div>
                    </div>
                </div>
            </div>
            <Footer />
        </div >
    )
}
export default Inquire