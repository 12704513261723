import PackageCard from "../../PackageCard/PackageCard"

import BoatImg from "../../../static/img/boat.jpg"

const QuoteablePackage = {
    name: "Boats, Trucks, Camper Vans Etc.",
    description: "We don’t do just cars! Everything needs to shine like a diamond!! Inquire below for a custom quote on detailing your pride and joy.",
    price: 0,
    timeEstimate: 0,
    img: BoatImg
}


const GetAQuote = () => {
    return (
        <div className="max-w-[100vw] md:px-10 my-10">
            <div className="lg:text-4xl text-2xl text-gray-300 lg:my-10 my-5 border-b  mx-2 border-orange-500 py-2">
                <p>
                    Got Something Different?
                </p>
            </div>
            <div className="w-full grid grid-cols-8 gap-10 my-10" >
                <div className="lg:col-span-4 col-span-8 lg:col-start-3">
                    <PackageCard package={QuoteablePackage} />
                </div>
            </div>
        </div>
    )
}
export default GetAQuote