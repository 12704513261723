import TopBar from "../Components/Header/TopBar"
import Footer from "../Components/Footer/Footer"
import PackageDisplay from "../Components/Packages/Packages"

const Packages = () => {
    return (
        <div className=" min-h-[100vh]" id="packages">
            <TopBar />
            <PackageDisplay />
            <Footer />
        </div>
    )
}

export default Packages