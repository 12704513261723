import TopBar from "./TopBar"

const Header = () => {
    return (
        <div className="md:h-[110vh]" id="header">
            <TopBar />
            <div className="hidden md:block absolute top-[85%] right-0 mr-20 text-right text-gray-200 text-5xl font-bold font-sans">
                Professional and outstanding
                <br />
                quality that comes to you!
            </div>
        </div>
    )
}
export default Header