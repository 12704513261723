import { Link, useLocation } from "react-router-dom"
import logo from "../../static/img/logo/logo.png"

const TopBarInquire = () => {

    const location = useLocation();
    return (
        <div className="text-gray-100  lg:w-[60%] w-full mx-auto" id="topBar">
            {/* <div className="lg:grid hidden grid-cols-12 mx-4 ml-8"> */}
            {/* <div className="col-start-1 col-span-3 mt-4" id="topBarLogo">
                    <Link to={"/"}>
                        <img src={logo} alt="Logo" />
                    </Link>
                </div> */}
            {/* <div className="grid grid-cols-12 gap-4 right-0 col-span-6 col-start-7 sticky-nav  mt-4 max-h-[4rem] text-3xl" >
                    <div className="grid grid-cols-8 gap-4 right-0 col-span-6 col-start-7 sticky-nav  mt-4 max-h-[4rem] text-3xl" >
                        <Link
                            className="btn-link col-span-4  mt-15"
                            to="/packages"
                        >
                            <span class={"text " + (location.pathname.includes("packages") ? "text-orange-500" : "")}>Packages</span>
                            <span class="line -right"></span>
                            <span class="line -top"></span>
                            <span class="line -left"></span>
                            <span class="line -bottom"></span>
                        </Link>
                        <Link
                            className="btn-link col-span-4  mt-15"
                            to="/inquire">
                            <span class={"text " + (location.pathname.includes("inquire") ? "text-orange-500" : "")}>Inquire</span>
                            <span class="line -right"></span>
                            <span class="line -top"></span>
                            <span class="line -left"></span>
                            <span class="line -bottom"></span>
                        </Link>
                        <Link
                            className="btn-link col-span-4  mt-15"
                            to="/inquire">
                            <span class={"text " + (location.pathname.includes("gallery") ? "text-orange-500" : "")}>Gallery</span>
                            <span class="line -right"></span>
                            <span class="line -top"></span>
                            <span class="line -left"></span>
                            <span class="line -bottom"></span>
                        </Link>
                    </div>
                </div> */}
            <div className=" flex  flex-col mx-4">
                <div className="md:w-1/2 w-full mx-auto mt-4" id="topBarLogo"> {/* TODO: change to 2 then 1 at scroll */}
                    <Link to={"/"}>
                        <img src={logo} alt="Logo" />
                    </Link>
                </div>

                {/* <div className="grid grid-cols-12 gap-4 col-span-6 col-start-7 sticky-nav  mt-4 max-h-[4rem] md:text-2xl text-md" > */}
                <div className="grid grid-cols-8 gap-4   sticky-nav  mt-4 max-h-[4rem] md:text-2xl text-md" >
                    <Link
                        className="btn-link col-span-4  mt-15"
                        to="/packages"
                    >
                        <span class={"text " + (location.pathname.includes("packages") ? "text-orange-500" : "")}>Packages</span>
                        <span class="line -right"></span>
                        <span class="line -top"></span>
                        <span class="line -left"></span>
                        <span class="line -bottom"></span>
                    </Link>
                    <Link
                        className="btn-link col-span-4  mt-15"
                        to="/inquire">
                        <span class={"text " + (location.pathname.includes("inquire") ? "text-orange-500" : "")}>Inquire</span>
                        <span class="line -right"></span>
                        <span class="line -top"></span>
                        <span class="line -left"></span>
                        <span class="line -bottom"></span>
                    </Link>
                    {/* <Link
                        className="btn-link col-span-4  mt-15"
                        to="/inquire">
                        <span class={"text " + (location.pathname.includes("gallery") ? "text-orange-500" : "")}>Gallery</span>
                        <span class="line -right"></span>
                        <span class="line -top"></span>
                        <span class="line -left"></span>
                        <span class="line -bottom"></span>
                    </Link> */}
                </div>
            </div>

        </div>
    )
}
export default TopBarInquire