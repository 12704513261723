import PackageCard from "../../PackageCard/PackageCard"

import engineBayDetail from "../../../static/img/detailing/EngineBay.webp"
import trimDetail from "../../../static/img/detailing/trimDetail.webp"


const AdditionalPackages = [
    {
        name: "Engine Bay Detail",
        description: "Engine bay degreased and washed, snow foamed and hand washed with all plastics dressed.",
        price: 50,
        timeEstimate: 0,
        img: engineBayDetail,
        imgCredit: "Photo by Theo on Unsplash"
    },
    {
        name: "Exterior Plastic Trim Revival",
        description: "All plastics and trims prepped, revived and sealed. Reccomended after a classic clean.",
        price: 120,
        timeEstimate: 0,
        img: trimDetail
    },
]



const OtherPackages = () => {
    return (
        <div className="w-full md:px-10 my-10">
            <div className="lg:text-4xl text-2xl text-gray-300 lg:my-10 my-5 border-b mx-2  border-orange-500 py-2">
                <p>
                    Additional Packages
                </p>
            </div>
            <div className=" grid grid-cols-8 gap-10">
                {
                    AdditionalPackages.map((pack) => {
                        if (AdditionalPackages.length % 2 === 0 || (AdditionalPackages.length % 2 != 0 && AdditionalPackages.indexOf(pack) != AdditionalPackages.length - 1)) {
                            return (
                                <div className={"lg:col-span-4 col-span-8 "}>
                                    <PackageCard package={pack} />
                                </div>
                            )
                        }
                    })
                }
            </div>
            {
                AdditionalPackages.length % 2 != 0 ?
                    <div className="w-full grid grid-cols-8 gap-10 my-10" >
                        <div className="lg:col-span-4 col-span-8 lg:col-start-3">
                            <PackageCard package={AdditionalPackages[AdditionalPackages.length - 1]} />
                        </div>
                    </div>
                    :
                    <></>
            }
        </div>
    )
}
export default OtherPackages