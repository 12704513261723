import { useState } from "react"
import { FaChevronUp } from "react-icons/fa"
import { Link } from "react-router-dom"


const PackageCard = (props) => {
    const [show, setShow] = useState(false)

    return (
        <div className="2xl:p-5 p-2 max-w-full">
            <div class="bg rounded-lg shadow bg-neutral-800 w-full group overflow-hidden">
                <div className={"min-h-[20rem] overflow-hidden bg-center bg-cover flex justify-between rounded-t-lg"} style={{ backgroundImage: `url(${props.package.img})` }}>
                </div>
                <div class="pt-2 h-[6rem]">
                    <div className={"bg-neutral-800 flex flex-col justify-between min-h-[25rem] max-h-[25rem] duration-200 ease-in-out " + (show ? "translate-y-[-20rem]" : "")}>
                        <div className={"grid grid-cols-12 "}>
                            <div className="col-span-11 ml-5">
                                <h5 class="mb-1 text-lg 2xl:text-2xl font-bold tracking-tight text-white">
                                    {props.package.name}
                                </h5>


                                <h5 class="mb-2 text-lg 2xl:text-2xl tracking-tight text-white">
                                    {props.package.price > 0 ? "Starting From: $" + (props.package.price.toFixed(2)) : "Prices vary"}
                                </h5>




                            </div>
                            <div className={"col-span-1 flex flex-col my-4 text-2xl text-gray-200 " + (show ? "justify-start" : "justify-end")}>
                                <FaChevronUp className={"cursor-pointer hover:text-orange-500 duration-200 ease-out " + (show ? "rotate-180" : "")} onClick={() => setShow(() => !show)} />
                            </div>


                        </div>
                        <div className={"grid grid-cols-12 bg-neutral-800 z-40 mt-10"}>
                            <div className="col-span-12">
                                <p class="text-lg mb-3 mx-5 font-normal text-gray-400">
                                    {(props.package.description)}
                                </p>

                            </div>
                        </div>
                        <div className={"grid grid-cols-12 bg-neutral-800 z-40 mt-10"}>
                            <div className="col-span-8 col-start-3">
                                <Link
                                    to={{ pathname: "/inquire" }}
                                    state={props.package}
                                    className="block w-full text-center py-2 px-3 bg-orange-500 rounded-lg text-xl text-gray-100 font-semibold hover:bg-orange-400">
                                    Inquire Now
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )

}

export default PackageCard