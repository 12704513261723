import PackageCard from "../../PackageCard/PackageCard"

// Images
import classicCleanImg from "../../../static/img/detailing/ClassicClean.webp"
import interiorDetailImg from "../../../static/img/detailing/Shampoo.jpg"
import exteriorDetailImg from "../../../static/img/detailing/Polisher.jpg"
import multiStageDetailImg from "../../../static/img/detailing/SwirlPolish.jpg"
import fullDetailImg from "../../../static/img/detailing/internalBrush.jpg"



const Packages = [
    {
        name: "Classic Clean",
        description: "For vehicles that need just a basic once over in and out to provide a convenient, efficient and effective weekly, fortnightly or monthly maintenance routine.",
        price: 140,
        timeEstimate: 0,
        img: classicCleanImg
    },
    {
        name: "Interior Detail",
        description: "A classic clean with: a shampoo & heavy clean of all upholstery, deep leather clean and all trims dressed & detailed.",
        price: 300,
        timeEstimate: 0,
        img: interiorDetailImg
    },
    {
        name: "Single Stage Exterior Detail",
        description: "A classic clean with: clay bar treatment to external paint, single stage polish to external paint work, buffed by hand and a spray on sealant used to protect the paintwork. Effective against light scratches and swirls.",
        price: 300,
        timeEstimate: 0,
        img: exteriorDetailImg
    },
    {
        name: "Multi Stage Exterior Detail",
        description: "A classic clean with: clay bar treatment to external paint, multiple stage polish to external paint work, buffed by hand and a spray on sealant used to protect the paintwork. Effective against medium & heavy scratches and swirls as well as oxidised paint.",
        price: 400,
        timeEstimate: 0,
        img: multiStageDetailImg
    },
    {
        name: "Full/Pre-sale Detail",
        description: "Full vehicle clean, classic clean paired with an Interior Detail, Exterior detail and an engine bay clean and dress.",
        price: 420,
        timeEstimate: 0,
        img: fullDetailImg
    },
]



const DetailingPackages = () => {
    return (
        <div className="w-full md:px-10 my-10">
            <div className="lg:text-4xl text-2xl text-gray-300 lg:my-10 my-5 mx-2 border-b border-orange-500 py-2">
                <p>
                    Detailing Packages
                </p>
            </div>
            <div className=" grid grid-cols-8 gap-10">
                {
                    Packages.map((pack) => {
                        if (Packages.length % 2 === 0 || (Packages.length % 2 != 0 && Packages.indexOf(pack) != Packages.length - 1)) {
                            return (
                                <div className={"lg:col-span-4 col-span-8 "}>
                                    <PackageCard package={pack} />
                                </div>
                            )
                        }
                    })
                }
            </div>
            {
                Packages.length % 2 != 0 ?
                    <div className="w-full grid grid-cols-8 gap-10 my-10" >
                        <div className="lg:col-span-4 col-span-8 lg:col-start-3">
                            <PackageCard package={Packages[Packages.length - 1]} />
                        </div>
                    </div>
                    :
                    <></>
            }
        </div>
    )
}
export default DetailingPackages