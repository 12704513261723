import car1 from "../../static/img/lamboPurple.jpg"
import brush from "../../static/img/detailing/BrushDetail.jpg"
import boat from "../../static/img/boat.jpg"

import ShowcaseContact from "./ShowcaseContact"
import ShowcaseLower from "./ShowcaseLower"
import ShowcaseBlurb from "./ShowcaseBlurb"
import ImageDisplay from "../Utility/ImageDisplay"

const Showcase = () => {
    let images = [
        {
            img: car1,
            text: "Entrust your prized possession with us, ensuring it continues to look as good as it should."
        },
        {
            img: brush,
            text: "Specializing in interior trim cleaning, trim restoration and stains."
        },
        {
            img: boat,
            text: "Detailing for all kinds of vehicles, including boats, trucks and more."
        },
    ]

    return (
        <div className="pt-10">
            <ImageDisplay images={images} />
            {/* Contact */}
            <ShowcaseContact />
            <ShowcaseLower />
            <ShowcaseBlurb />
        </div>
    )

}

export default Showcase