import { Link } from "react-router-dom"

const ShowcaseBlurb = () => {

    return (
        <div className="w-full flex justify-center">

        </div>
    )

}

export default ShowcaseBlurb