import Showcase from "../../Showcase/Showcase"


const Body = () => {
    return (
        <div className="w-full mx-auto">
            <Showcase />
        </div>
    )
}
export default Body