
const ImageDisplay = (props) => {


    return (
        <div>
            <div className="lg:hidden flex flex-col mt-20 text-gray-300">
                {
                    props.images.map((image) => {


                        return (
                            <div className="w-full">
                                <div className={"h-[15rem] sm:h-[25rem] overflow-hidden bg-center bg-cover w-full"} style={{ backgroundImage: `url(${image.img})` }}>
                                </div>
                                <div className=" text-center flex justify-center">
                                    <div className="text-xl font-thin group-hover:underline decoration-orange-500 transition-all duration-600 ease-in-out  w-[75%] my-4">
                                        {image.text}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div className="lg:grid hidden grid-cols-12 my-20 text-gray-300">
                {
                    props.images.map((image) => {


                        return (
                            <div className="col-span-4 flex flex-col group  ">
                                <div className={"min-h-[20rem] overflow-hidden bg-center bg-cover flex justify-between"} style={{ backgroundImage: `url(${image.img})` }}>
                                    <div className="h-full skew-x-[-10deg] group-hover:skew-x-[-10deg] group-hover:ml-[-7rem] w-[9rem] ml-[-5rem] bg-neutral-900 transition-all duration-400 ease-in-out"></div>
                                    <div className="h-full skew-x-[-10deg] group-hover:skew-x-[-10deg] group-hover:mr-[-7rem] w-[9rem] mr-[-5rem] bg-neutral-900 transition-all duration-600 ease-in-out"></div>
                                    {/* <img src={image} className="min-w-full object-center  shadow-xl top-[-50%] left-[50%] max-w-full" /> */}
                                </div>
                                <div className="mx-20 text-center flex justify-center">
                                    <div className="text-xl font-thin group-hover:underline decoration-orange-500 transition-all duration-600 ease-in-out  w-[75%] my-4">
                                        {image.text}
                                    </div>
                                </div>

                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default ImageDisplay