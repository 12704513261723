import { FaChevronRight } from "react-icons/fa"
import { Field, Form, Formik } from "formik"
import { useEffect, useState } from "react"
import { init, send } from "@emailjs/browser"

const ShowcaseContact = () => {

    const [displayContact, setDisplayContact] = useState(false)
    const [loading, setLoading] = useState(false)
    const [emailError, setEmailError] = useState("")
    const [emailSuccessMessage, setEmailSuccessMessage] = useState("")
    useEffect(() => {
        init('VdUaa-KVPmYLAzt3_')
    }, [])
    return (
        <div className="w-full flex justify-center">
            {/* <Link className="btn bg-orange-500 rounded-lg p-5 transition-all hover:bg-orange-400 duration-200 ease-in-out text-4xl text-gray-100" to="/inquire">
                Contact us today for a free quote
            </Link> */}
            <div className="overflow-hidden w-full min-h-[10rem]">
                <div className={"mx-auto lg:w-[50%] text-center p-5 lg:text-4xl text-gray-100 cursor-pointer bg-orange-500 z-40 relative "} onClick={() => setDisplayContact(() => !displayContact)}>
                    Contact us today for a free quote <FaChevronRight className={"inline ease-in-out duration-300 " + (displayContact ? "rotate-90" : "")} />
                </div>
                <div className={" z-0 ease-in-out duration-300 rounded-xl flex justify-center transition-all min-h-[30rem] relative " + (displayContact ? "mt-[0rem]" : "mt-[-40rem]")}>
                    <div className={"lg:w-[50%] w-full h-full bg-neutral-800"}>
                        <Formik
                            initialValues={{
                                fullName: "",
                                email: "",
                                text: ""
                            }}
                            onSubmit={(values, { resetForm }) => {
                                if (!values.fullName || !values.email || !values.text) {
                                    setLoading(() => false)
                                    setEmailError(() => "You need to enter name, email and quote details")
                                    setTimeout(() => {
                                        setEmailError(() => "")
                                    }, 4000)
                                } else {
                                    send('service_q8j38cl', 'template_qk76aii', values)
                                        .then((result) => {
                                            console.log(result.text);
                                            setLoading(() => false)
                                            setEmailSuccessMessage(() => "Thank you for your email " + values.fullName + ", we'll be in touch shortly.")
                                            resetForm();
                                            setTimeout(() => {
                                                setEmailSuccessMessage(() => "")
                                            }, 4000)
                                        }, (error) => {
                                            console.log(error.text);
                                            setEmailError(() => "There was an error sending the email: " + error.text)
                                        });
                                }

                            }}
                        >
                            {(formik) => (
                                <Form className="w-[90%] mx-auto mb-5" onSubmit={formik.handleSubmit}>
                                    {
                                        emailError != "" ?
                                            <div class={"md:flex md:items-center mb-6 mt-6 "}>
                                                <div className="m-4 p-2 rounded-2xl w-full bg-red-500">
                                                    <div className="w-full text-gray-200 text-center font-bold">
                                                        {emailError}
                                                    </div>
                                                    <div className="w-full text-gray-200 text-center">
                                                        If this persists, please use our contact details at the bottom of the page.
                                                    </div>
                                                </div>

                                            </div>
                                            :
                                            <></>
                                    }
                                    {
                                        emailSuccessMessage != "" ?
                                            <div class={"md:flex md:items-center mb-6 mt-6 "}>
                                                <div className="m-4 p-2 rounded-2xl w-full bg-green-700">
                                                    <div className="w-full text-gray-200 text-center">
                                                        {emailSuccessMessage}
                                                    </div>
                                                </div>

                                            </div>
                                            :
                                            <></>
                                    }

                                    <div class="md:flex md:items-center mb-6 mt-6">
                                        <div class="w-[20%]">
                                            <label class="block text-gray-500 font-bold  mb-1 md:mb-0 pr-4" for="inline-full-name">
                                                Full Name
                                            </label>
                                        </div>
                                        <div class="md:w-full">
                                            <Field
                                                class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-orange-500"
                                                type="text"
                                                placeholder="Your full name"
                                                name="fullName"
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div class="md:flex md:items-center mb-6 mt-6">
                                        <div class="w-[20%]">
                                            <label class="block text-gray-500 font-bold  mb-1 md:mb-0 pr-4" for="inline-full-name">
                                                Email
                                            </label>
                                        </div>
                                        <div class="md:w-full">
                                            <Field
                                                class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-orange-500"
                                                type="email"
                                                placeholder="goodDetailing@email.com"
                                                name="email"
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div class="md:flex md:items-center mb-6 mt-6">
                                        <div class="w-[20%]">
                                            <label class="block text-gray-500 font-bold  mb-1 md:mb-0 pr-4" for="inline-full-name">
                                                Quote Details
                                            </label>
                                        </div>
                                        <div class="md:w-full">
                                            <Field
                                                class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-orange-500"
                                                component="textarea"
                                                rows="10"
                                                placeholder="Tell us about your ride and the services you're interested in"
                                                name="text"
                                            />
                                        </div>
                                    </div>
                                    <div className="w-full flex justify-center">
                                        <button type="submit" className="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded w-full" onClick={() => setLoading(() => true)}>
                                            {
                                                !loading ?
                                                    <div >
                                                        Submit
                                                    </div>
                                                    :
                                                    <div className="loadingIcon"></div>
                                            }
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                        <div>

                        </div>
                    </div>
                </div>
            </div>


        </div>
    )

}

export default ShowcaseContact