import OtherPackages from "./Additional/AdditionalPackages"
import DetailingPackages from "./Detailing/DetailingPackages"
import GetAQuote from "./GetAQuote/GetAQuote"

const PackageDisplay = () => {
    return (
        <div className="lg:w-[80%] xl:w-[65%] max-w-[100%] mx-auto my-20">
            <DetailingPackages />
            <OtherPackages />
            <GetAQuote />

        </div>
    )
}
export default PackageDisplay